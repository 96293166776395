#nbPotentialMatch {
    display: inline-block;
    position: relative;
    top: 0.5px;
}

#feedbackButton {
    position: absolute;
    top: 10px;
    left: 10px;

    margin-right: 5px;
    cursor: pointer;
    background-color: #0b1ba5;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    color: #fff;
}

#feedbackButton:hover, .btnGetCurrentPosition:hover  {
    background-color: #5f6ce1;
}


#feedback {
    position: absolute;
    top: 35px;
    left: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 3px 5px rgb(0 0 0 / 25%);
    border-radius: 5px;
    background-color: white;
}

.btnUpdateUser {
    width: 30%;
    margin: auto;
    background-color: #909090;
    appearance: none;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: not-allowed;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .btnGetCurrentPosition {
    margin-right: 5px;
    cursor: pointer;
    background-color: #0b1ba5;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    color: #fff;
  }
  
  #updatesUserToPerform {
    background-color: #2ea44f;
    cursor: pointer;
    transition: 0.5s;
    animation: shake 1s;
  }
  
  #updatesUserToPerform:hover {
    background-color: #187934;
  }
  
  #deleteUser {
    background-color: #ff0000;
    cursor: pointer;
  }

  #deleteUser:hover {
    background-color: #981f1f;
  }
  
  .updateCategories {
    font-size: 1.5em;
    ;
  }

/* Profile images settings */
#listImageProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.profileImageHolder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
  margin: 5px;
  border-radius: 5px;
  width: 120px;
  height: 180px;
}

.deletePhotoButton {
  position: absolute;
  top: 188px
}

.photoNumber {
  color: rgb(200, 200, 200);
  font-size: 30px;
}

.aah{
  background-color: red;
}

.profileImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  cursor: move
}

#containerImage {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
}

#canvasImage {
  z-index: 1;
  border-style: dotted;
  border-radius: 25px;
  background-color: white;
  width: 400px;
  height: 600px;
}

#canvasBeforeSubmit {
  width: 120px;
  height: 180px;
  position: absolute;
  border-radius: 5px;
}

#buttonImage {
  background-color: #2ea44f;
  width: 40px;
  border-radius: 30px;
  padding: 20px;
  margin: 20px;
  border: none;
  cursor: pointer;
}

#buttonImage:hover {
  background-color: #187934;
}

input[type="file"]::file-selector-button {
  padding: 2px 16px;
  border-radius: 4px;
  cursor: pointer;
}

#pickImage {
  position: relative;
  left: 85px;
  top: -80px;
}

#saveImage {
  position: relative;
  top: 80px;
}

.inputsAlign > input {
  width: 170px;
}
.inputsAlign > div > input {
  width: 170px;
}

/* // TODO : faire un zoom taille reelle quand on passe la souris sur l'image */