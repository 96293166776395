#dashboard {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 9fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'display'
    'activitySwitcher';
  justify-items: stretch;
  align-items: center;
  justify-content: start;
  row-gap: 10px;
}

.display {
  grid-area: display;
  width: 100%;
  height: 100%;
  overflow: auto;
}

#activitySwitcher {
  grid-area: activitySwitcher;
  background-color: rgb(250, 225, 250);
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
  margin: 0px 5px 10px 5px;
}

#userProfile {
  display: flex;
  flex-direction: column;
}

#userProfile>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2px;
}

#imageAndInfos {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

#targetImage {
  width: 600px;
  height: 900px;
  position: absolute;
  border-radius: 20px;
}

#eee {
  word-break: break-word;
}

.inputsAlign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-left: 50px;
  margin-right: 50px;
  border-bottom: 1px solid #dadde1;
  padding-bottom: 13px;
  padding-top: 13px;
  font-size: 14px;
}

#noLoveFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2em;
}

#dotImage {
  width: 100%;
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#dotImage>div {
  margin: 10px;
}

#currentImage {
  color: red;
  font-size: 22px;
}

#targetOverview {
  z-index: 1;
  font-size: 2em;
  background-color: rgb(255, 255, 255); 
  border-radius: 20px;
  width: 99%;
}

#actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  /* margin-left: 10px;
  margin-right: 10px; */
}

#actionButtons>button {
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border-radius: 2em;
  color: #FFFFFF;
  background-color: #4eb5f1;
  border: none;
  padding: 5px 15px;
  font-size: 1em;
}

#actionButtons>button:hover {
  background-color: #278dc8;
}