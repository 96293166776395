#matches {
    display: flex;
    flex-direction: column;
}

#matchesWelcome {
    margin: auto;
    padding-top: 3px;
}

#matchesPreview {
    display: flex;
    flex-direction: row;
    overflow: auto;
    border-bottom: 1px solid #dadde1;
    height: 160px;
}

#previewImage {
    border-radius: 10px;
    width: 60px;
    height: 90px;
    border: solid 1px;
}

#previewImage {
    cursor: pointer;
}

#buttonBackListLovers {
    height: 70px;
    background-color: #909090;
    appearance: none;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    /* line-height: 20px; */
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

#buttonBackListLovers:hover {
    background-color: #484848;
    cursor: pointer;
}

.lover {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

#messagesList {
    overflow: auto;
}

#formPostMessage {
    display: flex;
    flex-direction: row;
    border: thin solid grey;
    border-radius: 15px;
    width: 94%;
    padding: 3px;
    margin-left: 3%;
    margin-top: auto;
}

#chatText {
    border-radius: 15px;
    border-width: thin;
    display: flex;
    flex-direction: row;
    /* border: 1px solid grey; */
    flex-grow: 2;
    border: none;

}

#postMessageBtn {
    border: 1px solid rgb(255, 0, 115);
    border-radius: 15px;
}

#discussion {
    /* margin-top: 1000px; */
    position: relative;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ownMessagePosition {
    margin-left: auto;
}

.ownMessagePosition, .otherMessagePosition {
    width: 70%;
}

.message {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 1px;
    border-radius: 5px; 
    padding-left: 10px;
      /* padding-top: 1px;
    padding-bottom: 1px; */
    color: white;
}

.ownMessage {
    background-color: #00bfff;
}

.otherMessage {
    background-color: #b0c4de;
}

.datetimeMessage {
    margin-left: 5px;
    font-size: 0.8em;
    margin-top: -0.2em;
    margin-bottom: 0.1em;
}

#loverLasteSeen {
    position: absolute;
    left: 10px;
    width: 95%;
    border-top: 2px solid black;
}

#previewDiscussion {
    /* margin-top: 1000px; */
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* List of discussion */

#listOfDiscussionGreating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 40px;
}

#ongoingDiscussions {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.discussionBlocPreview {
    all: unset;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadde1;
    border-radius: 5px;
}

.discussionBlocPreview:hover {
    transition: 1s;
    background-color: rgb(239, 223, 223);
}

.previewName {
    font-weight: bold;
}

.tickSeen {
    position: relative;
    width: 5px;
    height: 5px;
    top: 3px;
    left: 8px;
    float: left;
}

.tickUnseen {
    position: relative;
    width: 5px;
    height: 5px;
    top: 3px;
    left: 8px;
    float: left;
}

.previewMessage {
    display: flex;
}

.PreviewUnSeenMessages {



      /* circle shape, size and position */
  position: absolute;
  right: -0.7em;
  top: -0.7em;
  min-width: 1.6em; /* or width, explained below. */
  height: 1.6em;
  border-radius: 0.8em; /* or 50%, explained below. */
  border: 0.05em solid white;
  background-color: red;

  /* number size and position */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  color: white;
}

/* General info on selected user for discussion */

#generalInfosTargetDiscussion {
    display: flex;
    margin-bottom: 5px;
    margin-top: 10px;
    align-items: center;
}

#lineDivider {
    margin-bottom: 5px;
    border-bottom: 1px solid #dadde1;
}

#generalInfosTargetDiscussion > * {
    margin-left: 10px;
}

#nameLoverDiscussion {
    margin-left: 50px;
    font-size: 18px;
}