body {
  margin: 0;
}
  
* {
  box-sizing: border-box;
  font-family: monospace;
  /* background: #f0f2f5; */
}

.LoginPage {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 2fr 7fr 1fr;
  grid-template-areas: ". appTitle appTitle appTitle appTitle ."
                      ". appPresentation appPresentation appPresentation appPresentation ."
                      ". formsLoginRegister formsLoginRegister formsLoginRegister formsLoginRegister ."
                      "footer footer footer footer footer footer";
  justify-items: stretch;
  align-items: center;
  justify-content: start;
}

.formsLoginRegister {
  grid-area: formsLoginRegister;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 60px;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
  border-radius: 20px;
}

.logInfo {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
  padding: 10px;
  margin: 20px;
  width: 500px;
  border-radius: 10px;
  /* background: #ffffff; */
  animation: dropIn 0.5s ease backwards, opa 1.5s ease forwards; 
  animation-delay: calc(var(--order) * 0.5s);
}

.logInfo > form > label {
  color: darkblue;
  height: 20px;
  width: 240px;
  margin-top: 10px;
  margin-left: 10px;
  /* text-align: right; */
  clear: both;
  float:left;
  margin-right:15px;
}
.logInfo > form > input {
  height: 20px;
  width: 200px;
  border: 1px solid #000;
  margin-top: 10px;
  float: left;
}
.logInfo > form > div {
  height: 20px;
  width: 200px;
  /* border: 1px solid #000; */
  margin-top: 10px;
  float: left;
}

.genderPickRadio {
  position: relative;
  top:3px;
  margin-right: 15px;
}

@keyframes dropIn {from {transform: translateY(-1300px);} to {transform: translateY(0px);}}
@keyframes opa {from {opacity: 0;} to {opacity: 1;}}

.borderLine {
  border-bottom: 1px solid #dadde1;
  padding-bottom: 13px;
  font-size: 14px;
}

#footer {
  grid-area: footer;
  flex-direction: column;
}

.buttonSubmitLogin {
  width: 30%;
  height: 40px;
  background-color: #909090;
  appearance: none;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: not-allowed;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  /* line-height: 20px; */
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

#buttonSubmitAllowed {
  background-color: #2ea44f;
  cursor: pointer;
  transition: 0.5s;
  animation: shake 1s;
}

#buttonSubmitAllowed:hover {
  background-color: #0f702a;
}

.appTitle {
  grid-area: appTitle;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 30px;
  margin-bottom: 0px;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
  border-radius: 20px;
}

.appPresentation {
  grid-area: appPresentation;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 60px;
  margin-bottom: 0px;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
  border-radius: 20px;
}

#appPresentationArguments {
  display: flex;
  flex-direction: column;
}