.targetInfos {
    margin-left: 10px;
    color:black;
}

#activitySwitcher > div > button {
    /* width: 30%; */
    /* height: 40px; */
    background-color: #00a8ef;
    appearance: none;
    border: 1px solid rgba(21, 167, 167, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: not-allowed;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    /* font-size: 14px; */
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;

}

#activitySwitcher > div > button:hover {
    cursor: pointer;
    background-color: #007fef;

}